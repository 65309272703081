<template>
  <div class="page-list personnelfiles flex-1">
    <a-row
      v-if="false"
      class="filter-wrap  pdb-24"
      style="padding-bottom: 24px;"
    >
      财务分类
      <a-dropdown>
        <span class="ant-dropdown-link pointer">
          <i class="meiye-icon meiye-shezhi"></i>
          {{typeClass==3?'三级分类方式':typeClass==2?'二级分类模式':"一级分类方式"}}
          <i class="meiye-icon  meiye-moren1"></i>
        </span>
        <a-menu slot="overlay">
          <a-menu-item @click=";typeClass=1;changeTypes(1);">
            <span>一级分类方式</span>
          </a-menu-item>
          <a-menu-item @click=";typeClass=2;changeTypes(2);">
            <span>二级分类模式</span>
          </a-menu-item>
          <a-menu-item @click=";typeClass=3;changeTypes(3);">
            <span>三级分类方式</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </a-row>

    <a-row
      class="filter-wrap"
      style="padding-bottom: 0px;"
    >
      <a-button
        type="primary"
        class="table-btn-clk"
        style="margin-right:19px;margin-left: 0px;height: 32px;"
        @click="addRules('',1)"
      >
        <i
          class="meiye-icon meiye-routeicon-add-copy"
          style="color:#fff;font-size:12px;margin-right:4px;"
        ></i>
        添加类别
      </a-button>
    </a-row>
    <a-table
      class="table-manage "
      @change="tableChanged"
      :pagination="false"
      row-key="finance_category_id"
      :loading="table.loading"
      :columns="config.goodstype.columns1"
      :data-source="table.tableData"
      :expandIcon="customExpandIcon"
      :expanded-row-keys.sync="expandedRowKeys"
      childrenColumnName="children"
      :indentSize="15"
      :expandRowByClick="false"
      :expandIconAsCell="false"
      :expandIconColumnIndex="0"
      :scroll="{  y:getfullHeight(168)  }"
      v-if="table_key"
    >
      <template
        slot="_2"
        slot-scope="action, record"
      >
        <span v-if="!record.isEdit">{{ record.category_name||'--' }}
          <!-- 财务按照一级的来 -->
          <span
            v-if="false"
            @click="addftypes(record)"
            class="flex align-item-center  mgl-12 pointer"
            style="display:inline-block;text-align:center; border:1px solid #BACBC7;width: 24px;height: 24px;box-shadow: 0px 2px 3px 0px rgba(173,207,203,0.35), inset 0px -3px 0px 0px #DBEAE8;"
          >
            <!-- v-if="record.children&&record.children.length||!record.category_parent" -->
            <i class="meiye-icon meiye-routeicon-add pointer"></i>
          </span>

        </span>
        <span v-else>
          <a-input
            class="ant-input_1"
            v-model="record.category_name"
            style="width:283px;margin-right:16px"
            placeholder="输入分类名称，10个字以内"
            :maxLength="10"
            @pressEnter=";updataCa(record)"
          ></a-input>
          <a-button
            size="small"
            class="mgr-12 small_primary"
            type="primary"
            @click="handleBatch2('save',record)"
          >保存</a-button>
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            @click="handleBatch2('cancle',record)"
          >取消</a-button>

        </span>

      </template>

      <!-- <template
        slot="_3"
        slot-scope="action, record"
      >
        <span
          v-if="!record.isEdit"
          class="baseColor11 pointer"
          @click="openAssociation(record)"
        >{{ record.category_num?'record.category_num':12 }}</span>

      </template> -->

      <template
        slot="_7"
        slot-scope="action, record"
      >
        <a-button
          v-if="!record.isEdit"
          size="small"
          class="mgr-12 smallbtn"
          @click="addRules(record,3)"
        >编辑</a-button>
        <a-button
          v-if="!record.isEdit"
          size="small"
          class="mgr-12 smallbtn"
          @click="addRules(record,2)"
        >迁移</a-button>
        <a-button
          v-if="!record.isEdit"
          size="small"
          class="mgr-12 smallbtn"
          @click="handleBatch('delete',[record],'提示')"
        >删除</a-button>
        <a-button
          style="letter-spacing: -1px;"
          v-if="!record.isEdit"
          size="small"
          class="mgr-12 smallbtn"
          @click="handleBatch2('detail',record)"
        >查看商品</a-button>
      </template>
    </a-table>
    <!-- 选择目标分类 -->
    <a-modal
      title="选择目标分类"
      :visible="limitsModel.visible"
      @cancel=";limitsModel.visible=false; category_flag=false"
      :width="600"
      class="mrj_modal_normal "
      @ok="okmigrate"
      :footer="null"
    >
      <div class="limits_body2">
        <!-- 搜索框 -->
        <a-input
          @pressEnter="onChangeType"
          :placeholder="limitsModel.placeholderValue"
          class="ant-input2  ant-input_2"
          v-model="searchVal"
          @change="searchOnChange"
          style="width: 536px;margin-top: -4px;"
        >

          <i
            slot="prefix"
            class="meiye-icon meiye-sousuo"
          ></i>
        </a-input>
        <div
          class="transferBox2"
          style="height: 334px;width:536px;"
        >
          <!-- <a-input-search
            style="margin-bottom: 8px"
            placeholder="Search"
            @change="onChangeType"
          /> 
          <a-tree
            :expandedKeys.sync="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :tree-data="mockData"
            @expand="onExpand"
            v-model="checkedKeys"
            showIcon
            class="tree_types"
            v-if="treeshow"
            :defaultExpandAll="defaultExpandAll"
          > 
            <template
              slot="title"
              slot-scope="{ title,children, key}"
            >
              <span
                v-if="title.indexOf(searchValue) > -1"
                @click="titil2.title=title;titil2.children=children;titil2.key=key;"
              >
                {{ title.substr(0, title.indexOf(searchValue)) }}
                <span style="color: #f50">{{ searchValue }}</span> 
                {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
                <span
                  v-if="!children "
                  style="float:right;"
                > <i class="meiye-icon meiye-qianyi baseColor11"></i> 迁移</span>
                <span
                  v-else
                  style="width:48px;"
                > </span>
              </span>
              <span v-else>{{ title }} <span
                  v-if="!children "
                  style="float:right;"
                > <i class="meiye-icon meiye-qianyi baseColor11"></i> 迁移</span></span>
            </template>
          </a-tree>-->

          <!-- <a-input-search
            style="margin-bottom: 8px"
            placeholder="Search"
            v-model="searchVal"
            @change="searchOnChange"
            @search="onSearch"
          /> -->
          <a-tree
            :expanded-keys="iExpandedKeys"
            :auto-expand-parent="autoExpandParent"
            :tree-data="showData"
            @expand="onExpand"
            class="tree_types tree_types2"
            :replace-fields="{children:'children', key:'finance_category_id', value: 'finance_category_id', title: 'category_name'}"
          >
            <template
              slot="category_name"
              slot-scope="{ children,category_name,finance_category_id}"
            >
              <span
                class="openqianyi"
                v-if="category_name.indexOf(searchValue) > -1"
              >
                {{ category_name.substr(0, category_name.indexOf(searchValue)) }}
                <span style="color: #00B2A8;">{{ searchValue }}</span>
                {{ category_name.substr(category_name.indexOf(searchValue) + searchValue.length) }}
                <span
                  style="float:right; display:inline-block;"
                  class="qianytxt"
                  v-if="finance_category_id==titil1.finance_category_id"
                >当前位置</span>
                <span
                  v-else-if="!children && finance_category_id!=titil1.finance_category_id"
                  style="float:right;"
                  class="qianyicon"
                  @click="moveTo(finance_category_id,category_name)"
                > <i class="meiye-icon  meiye-qianyi baseColor11"></i> 迁移到此处</span>
                <span
                  v-else
                  style="width:48px;"
                > </span>
              </span>
              <span
                v-else
                class="openqianyi"
              >{{ category_name}}
                <span
                  class="qianyicon"
                  v-if="!children"
                  style="float:right;"
                > <i class="meiye-icon meiye-qianyi baseColor11"></i> 迁移到此处</span>
              </span>
            </template>
          </a-tree>

        </div>
      </div>
    </a-modal>
    <!-- 关联商品 服务 方案 储值卡 -->
    <a-modal
      :visible="association.visible"
      width="750px"
      wrapClassName="commonModel2"
      @cancel="association.visible=false"
      :footer="null"
      destroyOnClose
    >
      <div class="">
        <div
          class="consTabs"
          style="margin-bottom:24px;text-align: center;"
        >
          <a-radio-group
            v-model="association.flag"
            class="lable_radiogroup"
            style="width:320px;"
            @change="changeAssociation"
          >
            <a-radio-button
              value="1"
              style="width:80px;text-align: center;"
            >
              商品
            </a-radio-button>
            <a-radio-button
              value="2"
              style="width:80px;text-align: center;"
            >
              服务
            </a-radio-button>
            <a-radio-button
              value="3"
              style="width:80px;text-align: center;"
            >
              卡项
            </a-radio-button>
            <a-radio-button
              value="4"
              style="width:80px;text-align: center;"
            >
              储值卡
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="consTable">
          <a-table
            class="popTable3"
            :pagination="false"
            :rowKey="record=>record.finance_category_id"
            :loading="association.loading"
            :columns="association.config"
            :data-source="association.tableData"
          ></a-table>
          <div
            class="fenye small_pagination"
            id="quanfenye"
            style="height:24px;position:relative;"
          >
            <a-pagination
              v-if="association.pagination.total>0"
              size="small"
              :total="association.pagination.total"
              :defaultPageSize="8"
              v-model="association.pagination.current"
              @change="onShowSizeChange"
            />
            <span
              class="titNum"
              v-if="association.pagination.total"
              style="font-size: 14px;font-weight: 400;color:@fontColor3;bottom: 20px;left: 30px;color: rgba(45,56,53,0.8);"
            >共关联{{association.pagination.total}}件商品</span>
          </div>
        </div>
      </div>
      <template slot="title">
        <div style="margin: 3px 0;">
          <span
            class="titmodel"
            style="margin-right:8px;"
          >{{ association.title }}</span>

        </div>
      </template>
    </a-modal>
  </div>

</template>
<script> 
import storage from 'store'
// import { GetGoodslist, GetgoodsoptionsList, Creategoods, UpdateCustom, goodsInfo } from '@/api/commodity'
import { GetmanageGoodslist, GettypeFinanceList, CreatetypeFinanceList, DelatetypeFinanceList, UpdatetypeFinanceList, ShowtypeFinanceList, MovetypeFinanceList, TreetypeFinanceList } from '@/api/commodity'
import config from '../config'
import axios from 'axios'
import IconPicker from '@/components/intimate/IconPicker'
import apidata from './api'
let parentKey = []
const getParentKey = (key, tree) => {
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.key == key) {
      parentKey = node.key;
    } else {
      if (node.children) {
        if (node.children.some(item => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }

  }
  return parentKey;
};
const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const key = node.finance_category_id;
    dataList.push({ key, title: node.category_name, category_parent_path: node.category_parent_path });
    if (node.children) {
      generateList(node.children);
    }
  }
};
generateList(apidata.data);
console.log(dataList, '...dataList');
export default {
  components: { IconPicker },
  data() {
    return {
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        token: storage.get('token'),
        "Content-Type": 'multipart/form-data'
      },
      apidatas: apidata.data,
      typeClass: 1,//一级 二级 三级
      coptionsList: [],
      query: {
        category_name: undefined,
        limit: 10,
        page: 1,
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          finance_category_id: undefined,
          category_name: undefined,
          // category_icon: undefined,
          category_status: 1,//
          category_parent: 0,
          category_sort: 1
        }
      },
      treeData: [],
      selectreeObj: {
        children: 'children',
        title: 'category_name',
        key: 'finance_category_id',
        value: 'finance_category_id'
      },
      expandedRowKeys: [],
      table_key: 1,
      category_flag: false,
      limitsModel: {//迁移框
        visible: false,
        defaultExpandAll: false,
        // originalmockData: mockData1.mockdata,
        value: '',
        placeholderValue: '分类名称',
        mockData: [],
        targetKeys: ['1', '3', '16', '30'],
        treeshow: true,
        checkedKeys: [],
        // leftColumns: leftTableColumns,
        // rightColumns: rightTableColumns,
        limitsreplaceFields: {
          key: "finance_category_id",
          title: 'category_name',
          children: 'children '
        },

      },
      defaultExpandAll: false,
      treeshow: true,
      mockData: [],
      checkedKeys: [],
      ockData: [],
      // expandedKeys: [],
      // searchValue: '',
      // autoExpandParent: true,
      titil1: {},
      titil2: {},
      association: {
        visible: false,
        title: '品牌名称',
        obj: {
          name: 'www',
          num: '12',
        },
        loading: false,
        pagination: {
          current: 1,
          total: 1,
          pageSize: 8, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: false,
        },
        tableData: [
        ],
        config: [
          {
            title: '商品名称', //服务名称 方案名称 卡项名称
            dataIndex: 'goods_name',
            key: '_1',
            ellipsis: true,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '商品码',
            dataIndex: 'goods_bn',
            key: '_2',
            ellipsis: true,
            scopedSlots: { customRender: '_2' },
          },
        ],// 服务名称+商品码  卡项名称+商品码 方案名称+商品码 
        flag: '1',//商品名称1  服务名称2 方案名称3 卡项名称4
      },

      showData: [],
      defaultData: [],
      expandedKeys: [],
      searchVal: "",
      searchValue: "",
      iExpandedKeys: [],
      autoExpandParent: true,
    }
  },
  mounted() {
    this.getTreeData();
  },
  async created() {
    this.getList()
  },
  methods: {
    getTreeData() {
      this.showData = [];
      this.defaultData = [];
      //调用获取数据的接口
      let res = this.treeData

      for (let i = 0; i < res.length; i++) {
        let temp = res[i]
        this.defaultData.push(JSON.parse(JSON.stringify(temp)))
        this.showData = [...this.defaultData];
        this.recursionData(this.defaultData);//将每一层数据都赋上title的slot,以高亮显示搜索字段
        this.setThisExpandedKeys(temp)
        // console.log(temp.id)
      }

      // })
    },
    recursionData(node) {
      node.forEach(item => {
        item.scopedSlots = { title: 'category_name' }
        if (item.children && item.children.length) {
          this.recursionData(item.children)
        }
      })
    },
    setThisExpandedKeys(node) {
      //只展开一级目录
      if (node.children && node.children.length > 0) {
        this.iExpandedKeys.push(node.finance_category_id)
        //下方代码放开注释则默认展开所有节点

        // for (let a = 0; a < node.children.length; a++) {
        //   this.setThisExpandedKeys(node.children[a])
        // }
      }
    },
    onExpand(expandedKeys) {
      this.iExpandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    searchOnChange() {
      this.showData = [...this.defaultData];
      if (this.searchVal) {
        this.onSearch(this.searchVal);
      } else {
        this.searchValue = "";
        this.iExpandedKeys = [this.showData[0].finance_category_id];
      }
    },
    onSearch(val) {
      const value = val
      this.searchValue = value
      if (value != '') {
        let treeData = JSON.parse(JSON.stringify(this.showData));
        // 删除四级中未匹配到的数据
        this.deleteTreedata(treeData, val, 4);
        // 删除三级数据中未匹配到的数据
        this.deleteTreedata(treeData, val, 3);
        // 删除二级数据中未匹配到的数据
        this.deleteTreedata(treeData, val, 2);
        this.showData = [...treeData];
        // 展开所有树数据 
        this.expandAll(this.showData);
      } else {
        this.iExpandedKeys = [this.showData[0].finance_category_id];
      }
    },
    deleteTreedata(node, val, level) {
      //这里注意数组一定要从后面对比删除，否则数组从前面删了以后，顺序就乱掉，就只能删第一个了
      for (let len = node.length - 1; len >= 0; len--) {
        if (node[len].children && node[len].children.length) {
          this.deleteTreedata(node[len].children, val, level)
        } else {
          if (node[len].level == level) {
            let str = node[len].category_name;
            if (str.indexOf(val) < 0) {
              node.splice(len, 1);
            }
          }
        }
      }
    },
    expandAll(node) {
      console.log('nodenode', node);
      node.forEach(item => {
        if (item.children && item.children.length) {
          this.iExpandedKeys.push(item.finance_category_id)
          this.expandAll(item.children)
        }
      })
    },







    handleMenuClick({ key }) {
      this.handleBatch(key)
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.info.pic_url_loading = true
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        const { file_url } = info.file.response.data
        this.permissionInfo.info.permission_icon = file_url
        this.permissionInfo.info.pic_url_loading = false
      }
    },
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    // 删除 
    handleBatch(key, record, title = "删除") {

      let self = this
      let deleteRules = DelatetypeFinanceList
      const urlArr = [
        { name: deleteRules, key: 'delete', okType: 'primary', msg: '确定要删除吗？删除后将无法恢复，请谨慎操作！' },
      ]
      const activeObj = urlArr.filter(it => it.key == key)[0]
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: activeObj.msg,
        okText: '确定',
        closable: true,
        okType: activeObj.okType,
        cancelText: '取消',
        onOk() {
          const data = {
            finance_category_id: record[0].finance_category_id,
          };

          (activeObj.name)(data).then(res => {
            if (res.error_code == 0) {
              self.query.page = 1
              self.$message.success(res.data.message)
              self.getList()
            }
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        filter: {
          category_name: self.query.category_name
        },
        page: self.query.page,
        limit: self.query.limit
      }

      return GettypeFinanceList(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        // self.table.tableData = self.apidatas
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.limit, current: this.query.page }, {
          total: total
        })
        console.log(self.table.tableData, "///self.table.tableData");
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 财务 树形列表
    getCompanyoptionList(e) {
      TreetypeFinanceList().then(res => {
        this.treeRecursion(res.data)
        this.treeData = res.data
        this.category_flag = false
        // this.mockData = this.treeData
        // this.changeId2(this.mockData, 'finance_category_id', 'key')
        // this.changeId2(this.mockData, 'category_name', 'title')
        this.getTreeData()
        // console.log(this.mockData, ' this.limitsModel.mockData');
        console.log(this.treeData, '>>>this.category_options');
        if (!e) {
          // this.query.company_parent = res.data[0].company_id
          // this.permissionInfo.info.company_parent = res.data[0].company_id
          this.getList()
        }

      }).finally(i => {
        this.category_flag = false
      })
    },

    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { limit: Number(pageSize), page: Number(current) })
      this.getList()
    },

    changeStatus($event, e) {
      let obj = {}
      this.table.tableData.forEach(el => {
        if (e.role_id == el.role_id) {
          el.role_status = $event ? 1 : 0
          obj = el
        }
      })
      this.permissionInfo.type = 3 //编辑
      this.permissionInfo.info = {
        role_id: obj.role_id,
        // system_id: obj.system_id,
        role_name: obj.permission_name,
        permission_id_list: [],
        role_status: obj.role_status
      }
      this.okclickmod()
    },
    onChangePermissiontype(e) {
      console.log('radio checked', e.target.value, this.permissionInfo.info.permission_type);
    },
    // 提交按钮
    okclickmod(e) {
      let data = {
        ...this.permissionInfo.info
      }
      if (this.permissionInfo.type == 3) {
        delete data.created_at
        delete data.updated_at
        delete data.system_id
        UpdatetypeFinanceList(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            // this.getCompanyoptionList()
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        }).finally(e => {
          this.category_flag = false
        })
      } else {
        CreatetypeFinanceList(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            // this.getCompanyoptionList()
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        }).finally(e => {
          this.category_flag = false
        })
      }
    },
    async addRules(e, i) {

      // 先判断 typeClass是一级二级三级分类
      if (this.category_flag) {
        this.$message.error('您有编辑项未完成，请完成后操作！')
        return false
      }


      this.permissionInfo.isDisabled = false
      this.permissionInfo.info = {
        finance_category_id: undefined,
        category_name: undefined,
        category_status: 1,//
        category_parent: 0,
        category_sort: 1
      }
      if (i == 3) {
        this.category_flag = true
        // this.permissionInfo.isDisabled = true 
        this.permissionInfo.type = 3
        console.log(this.permissionInfo.info);
        this.table_key = 0
        this.table.tableData.forEach(el => {

          if (el.finance_category_id == e.finance_category_id) {
            el.isEdit = true
          }
        });
        this.table_key = 1
      } else if (i == 1) { //添加一级分类
        this.category_flag = true
        this.permissionInfo.type = 1
        this.table.tableData.unshift({
          "finance_category_id": '',
          "category_parent": 0,
          "category_name": "",
          "category_status": 1,
          "category_sort": 1,
          "isEdit": true,
        })

      } else if (i == 2) {//迁移
        // this.category_flag = true
        console.log(this.apidatas, 'this.apidatas');
        this.getCompanyoptionList()
        this.limitsModel.visible = true
        this.titil1 = e
      }

    },
    changeId2(objAry, key, newkey) {
      if (objAry != null) {
        objAry.forEach((item) => {
          Object.assign(item, {
            [newkey]: item[key] + "",
          });
          delete item[key];
          if (item.children && item.children.length) {
            this.changeId2(item.children, key, newkey);
          }
        });
      }
    },
    openDatails() {
      ShowtypeFinanceList({ finance_category_id: this.permissionInfo.info.finance_category_id }).then(res => {
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
      })
    },


    // 递归改变id key number
    treeRecursion(data) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el.company_id) == 'number') {
          el.company_id = el.company_id.toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      if (props.record.children && props.record.children.length > 0) {
        //有数据-展开时候图标
        if (props.expanded) {
          //这里的margin-right是为了让图标和字体有一点间距
          return (
            <span
              style="margin-right:10px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >

              <i class='meiye-icon meiye-jiantou meiye-jiantou_tree1' style="font-size: 12px;"></i>
            </span >
          );
          //无数据时-关闭的图标
        } else {
          return (
            <span
              style="margin-right:10px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <i class='meiye-icon meiye-jiantou meiye-jiantou_tree2' style=" font-size: 12px;"></i>
            </span>
          );
        }
      } else {
        // if (!props.record.finance_category_id) {
        // return <span style="margin-right:19px"></span>;
        // }
        //这里是为了让无图标子级的父元素也给了个margin-right，让它跟有子级的父元素在同一竖线上
        // return <span style="margin-right:19px"> <span class='pointer_tree '></span></span>;
      }
    },
    // 
    customExpandIcon2(props) {
      console.log(props, '>>>>>>props');
      if (props.children && props.children.length > 0) {
        //有数据-展开时候图标
        if (props.expanded) {
          //这里的margin-right是为了让图标和字体有一点间距
          return (
            <span
              style="margin-right:10px"

            >
              {/* onClick={(e) => {
                props.onExpand(props, e);
              }} */}
              <i class='meiye-icon meiye-jiantou meiye-jiantou_tree1' style="font-size: 12px;"></i>
            </span >
          );
          //无数据时-关闭的图标
        } else {
          return (
            <span
              style="margin-right:10px"

            >
              {/* onClick={(e) => {
                props.onExpand(props, e);
              }} */}
              <i class='meiye-icon meiye-jiantou meiye-jiantou_tree2' style=" font-size: 12px;"></i>
            </span>
          );
        }
        //  onClick={(e) => {
        // props.onExpand(props, e);
        //     }}
      } else {
        if (!props.finance_category_id) {
          return <span style="margin-right:19px"></span>;
        }
        //这里是为了让无图标子级的父元素也给了个margin-right，让它跟有子级的父元素在同一竖线上
        return <span style="margin-right:19px"> <span class='pointer_tree '></span></span>;
      }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.company_logo = res.data.url
      } else {
        this.$message.error(res.error.message)
      }


    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    // 获取icon
    getIconName(e) {
      this.permissionInfo.info.category_icon = e
    },
    changeTypes(i) {
      // 
      let arr1 = []
      let arr2 = []
      let arr3 = []
      let self = this
      // this.table.tableData=xxx
      // 
      let arrS1 = JSON.parse(JSON.stringify(this.apidatas))
      let arrS2 = JSON.parse(JSON.stringify(this.apidatas))
      let arrS3 = JSON.parse(JSON.stringify(this.apidatas))
      // if (i == 1) {
      //   arrS1.forEach((el, idx) => {
      //     delete el.children
      //     arr1.push(el)
      //   })

      // }
      if (i == 1) {
        arrS1.forEach((el, idx) => {
          if (el.children && el.children.length) {
            el.children.forEach((m, n) => {
              if (m.children && m.children.length) {
                delete m.children
                m.children = null
              }

            })
          }
          arr1.push(el)
        })
        self.table_key = 0
        self.$set(self.table.tableData, arr1);
        self.table.tableData = arrS1
        self.table_key = true
        console.log(arrS1, "arrS1");
      }
      if (i == 2) {
        arrS2.forEach((el, idx) => {
          if (el.children && el.children.length) {
            el.children.forEach((m, n) => {
              if (m.children && m.children.length) {
                m.children.forEach((j, k) => {
                  if (j.children && j.children.length) {
                    j.children = null
                    delete j.children
                  }
                })
              }
            })
          }

          arr2.push(el)
        })
        // arr2 = arrS2
        self.table.tableData = arrS2
        self.$set(self.table.tableData, arr2);
        console.log(arrS2, "arrS2");
        self.table_key++
      }
      if (i == 3) {
        arrS3.forEach((el, idx) => {
          if (el.children && el.children.length) {
            el.children.forEach((m, n) => {
              if (m.children && m.children.length) {
                m.children.forEach((j, k) => {
                  if (j.children && j.children.length) {
                    j.children.forEach((f, g) => {
                      if (f.children && f.children.length) {
                        delete f.children
                        f.children = null
                      }
                    })
                  }
                })
              }
            })
          }
          arr3.push(el)
        })
        console.log(arrS3, 'arrS3');
        // arr3 = arrS3
        self.table.tableData = arrS3
        self.$set(self.table.tableData, arr3);
        self.table_key++
      }

      console.log(self.table.tableData, 'self.table.tableData');
    },
    updataCa(e) {
      if (e.category_name == '' || !e.category_name) {
        return this.$message.error('分类名称不能为空')
      }
      console.log(e);
      if (e.finance_category_id) {
        // 调取编辑接口 
        this.permissionInfo.type = 3
        this.permissionInfo.info = {
          finance_category_id: e.finance_category_id,
          category_name: e.category_name,
          category_status: e.category_status,
          category_parent: e.category_parent,
          category_sort: e.category_sort
        }
        // this.``
        // this.okclickmod()
        // this.getList()
      } else {
        this.permissionInfo.type = 1
        this.permissionInfo.info = {
          finance_category_id: undefined,
          category_name: e.category_name,
          category_status: e.category_status,
          category_parent: e.finance_category_id,
          category_sort: e.category_sort
        }
        // 调取新增接口
        // this.getList()
      }
      this.okclickmod()

    },
    addftypes(record) {
      console.log(record);

      let obj = {
        "finance_category_id": '',
        "category_parent": record.finance_category_id,
        "category_name": "",
        "category_status": 1,
        "category_sort": 1,
        "isEdit": true,
      }
      let that = this
      this.table.tableData.forEach(el => {
        if (el.finance_category_id == record.finance_category_id) {
          if (!el.children) {
            el.children = []
          }
          el.children.unshift(obj)
          that.expandedRowKeys = [el.finance_category_id]
        } else {
          if (el.children && el.children.length) {
            el.children.forEach(m => {
              if (m.finance_category_id == record.finance_category_id) {
                m.children.unshift(obj)
                that.expandedRowKeys = [el.finance_category_id, m.finance_category_id]

              } else {
                if (m.children && m.children.length) {
                  m.children.forEach(j => {
                    if (j.finance_category_id == record.finance_category_id) {
                      j.children.unshift(obj)
                      that.expandedRowKeys = [el.finance_category_id, m.finance_category_id, j.finance_category_id]
                    } else {
                      if (j.children && j.children.length) {
                        j.children.forEach(g => {
                          if (g.finance_category_id == record.finance_category_id) {
                            g.children.unshift(obj)
                            that.expandedRowKeys = [el.finance_category_id, m.finance_category_id, j.finance_category_id, g.finance_category_id]
                          }
                        })
                      }
                    }
                  })
                }
              }
            })
          }
        }
      })
      // 点击具体某一个add 先判断是哪个层级加在哪个的下面
      // 现定义一个方法 递归找出table.tableDate的莫一项 然后再其children添加
      // 具体看看传参

    },
    // 选择目标分类
    // onExpand(expandedKeys) {
    //   this.expandedKeys = expandedKeys;
    //   this.autoExpandParent = false;
    // },
    onChangeType(e) {
      // 
      const value = e.target.value;
      // const expandedKeys = []
      // this.treeFindPath(this.mockData, data => data.title.includes(value), expandedKeys)
      // console.log(expandedKeys);
      // this.autoExpandParent = true
      // const expandedKeys = dataList
      //   .map(item => {
      //     if (item.title.indexOf(value) > -1) {
      //       return getParentKey(item.key, this.mockData);
      //     }
      //     return null;
      //   })
      //   .filter((item, i, self) => item && self.indexOf(item) == i);
      // 
      // dataList
      let obj = dataList.filter(item => { if (item && item.title.includes(value)) return item })
      let expandedKeys = []
      if (obj.length) {
        obj.forEach(el => {
          let a = el.category_parent_path
          el.category_parent_path = JSON.parse("[" + (a) + ']')
          // 
          expandedKeys = el.category_parent_path
          // expandedKeys.concat(el.category_parent_path.split(','))
        })
        // expandedKeys = obj.category_parent_path.split(',')
      }
      // 
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
      // this.treeshow = false
      // this.defaultExpandAll = true
      // this.treeshow = true
      // this.$forceUpdate()
      // 
    },
    getList2() {

    },
    // getIcon(props) {
    //   const { children, parentId } = props
    //   if (parentId == '0' || children.length > 0) {
    //     return <a-icon type="tags" theme="twoTone" />
    //   } else {
    //     return <a-icon type="tag" theme="twoTone" />
    //   }
    // },
    treeFindPath(tree, func, path = []) {
      if (!tree) return []
      for (const data of tree) {
        // 这里按照你的需求来存放最后返回的内容吧
        path.push(data.key)
        if (func(data)) return path
        if (data.children) {
          const findChildren = this.treeFindPath(data.children, func, path)
          if (findChildren.length) return findChildren
        }
        path.pop()
      }
      return []
    },
    okmigrate() {
      let self = this
      self.limitsModel.visible = false
      self.$confirm({
        title: '是否迁移，此操作不可逆，确定执行吗？',
        icon: () => this.$createElement('a-icon', {
          props: {
            type: 'exclamation-circle',
            theme: 'filled',
            color: 'red'
          },
        }),
        content: h =>
          <div>
            您即将把【 <span class='baseColor11' >{self.titil1.category_name}</span> 】的全部商品， 迁移到【<span class='baseColor11' >{self.titil2.category_name}</span>】
          </div>
        ,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          MovetypeFinanceList({
            finance_category_id: self.titil1.finance_category_id,
            to_finance_category_id: self.titil2.finance_category_id
          }).then((res) => {
            console.log('res :>> ', res);
            if (res.error_code == 0) {
              self.$message.success("迁移成功")
              self.limitsModel.visible = false
              self.query.page = 1
              self.getList()
            }
          })
        },
        onCancel() {
          self.limitsModel.visible = true
        },
      });
    },

    handleBatch2(e, i) { //查看商品

      // 
      this.permissionInfo.info = {
        ...i
      }

      let self = this
      if (e == 'save') {
        // 发送请求 新增保存
        let a = i.category_name.trim()
        if (!i.category_name || !a) {
          return this.$message.error('请填写分类名')
        }
        if (i.finance_category_id) {
          this.permissionInfo.type = 3
        } else {
          this.permissionInfo.type = 1
        }
        delete this.permissionInfo.info.isaddoredit
        this.permissionInfo.info.brand_name = i.brand_name
        this.query.page = 1
        this.okclickmod()
        this.category_flag = false
      }
      if (e == 'cancle') {
        this.category_flag = false
        if (i.finance_category_id) {
          this.table.tableData.forEach(el => {
            if (el.finance_category_id == i.finance_category_id)
              el.isEdit = false
          })
          this.query.page = 1
          this.getList()
        } else {
          this.table.tableData.shift()
        }
        this.$forceUpdate()
      }
      if (e == 'detail') {
        // 发送请求 新增保存
        if (this.category_flag) {
          this.$message.error('您有编辑项未完成，请完成后操作！')
          return false
        }
        this.category_flag = true
        if (i.finance_category_id) {
          this.permissionInfo.type = 3
        } else {
          this.permissionInfo.type = 1
        }
        delete this.permissionInfo.info.isaddoredit
        this.permissionInfo.info.brand_name = i.brand_name
        // 打开商品查看
        this.association.flag = '1'
        this.openAssociation(i)
        this.category_flag = false
      }
      self.category_flag = false
    },
    openAssociation(record) {
      // 
      this.category_flag = false
      this.association.visible = true
      this.association.obj = record
      this.association.title = record.category_name
      this.association.obj.num = 23

      let data = {
        filter: {
          finance_category_id: this.association.obj.finance_category_id,
        },
        page: this.association.pagination.current,
        limit: this.association.pagination.pageSize
      }
      if (this.association.flag == 1) {
        data.filter = { goods_type: [1, 2, 3, 4], finance_category_id: this.association.obj.finance_category_id, }
      }
      if (this.association.flag == 2) {
        data.filter = { goods_type: [5, 6], finance_category_id: this.association.obj.finance_category_id, }
      }
      if (this.association.flag == 3) {
        data.filter = { goods_type: 8, finance_category_id: this.association.obj.finance_category_id, }
      }
      if (this.association.flag == 4) {
        data.filter = { goods_type: 7, finance_category_id: this.association.obj.finance_category_id, }
      }
      GetmanageGoodslist(data).then(res => {
        this.association.tableData = res.data.list
        this.association.pagination.total = res.data.total

      })
    },
    changeAssociation(e) {
      console.log(e.target.value, "changeAssociation");
      if (e.target.value == 1) {
        this.association.config[0].title = '商品名称'
      }
      if (e.target.value == 2) {
        this.association.config[0].title = '服务名称'
      }
      if (e.target.value == 3) {
        this.association.config[0].title = '卡项名称'
      }
      if (e.target.value == 4) {
        this.association.config[0].title = '卡项名称'
      }
      this.openAssociation(this.association.obj)
      // 
    },
    onShowSizeChange(current, pageSize) {
      this.association.pagination.pageSize = pageSize;
      this.association.pagination.current = current;
      // 请求   this.association.tableData
      // this.opengrouptype();
    },
    moveTo(id, name) {
      this.titil2 = {
        finance_category_id: id,
        category_name: name
      }
      this.okmigrate()
      // confirm 框

    },

  },
  watch: {
    checkedKeys(val) {
      console.log('onCheck', val);
    },
  },

}
</script>
<style lang="less" scoped>
.personnelfiles {
  // padding: 12px;
  margin: 20px 18px 18px 18px;
  overflow: hidden;
  // background: #fff;
  // height: 100%;
  // min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-height: calc(100vh - 143px);
  background: #fff;
  height: calc(100% - 86px);
  .filter-wrap {
    background: #fff;
    border-radius: 4px 4px 0 0;
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.borderbox {
  margin-left: 4px;
  display: inline-block;
  border-radius: 6px;
  text-align: center;
  width: 20px;
  height: 18px;
  background: @btn2mianbgDColor;
  box-shadow: 0px 2px 3px 0px @btn2mianOutDColor,
    inset 0px -2px 0px 0px @btn2mianInsertDColor;
  border-radius: 4px;
  border: 1px solid @btn2mianboderDColor;
  line-height: 14px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.bigdropdown_imga {
  display: none;
}
.bigdropdown_img1a {
  display: none;
}
.bigdropdown {
  position: relative;
  width: 100px;
  height: 18px;
  display: inline-block;
  line-height: 18px;
  .borderbox {
    position: absolute;
    top: 0;
    left: 29px;
    i {
      font-size: 8px;
      margin-top: -1px;
      transform: scale(0.83);
      display: inline-block;
      color: @btn2mianfontDColor;
    }
    &:hover {
      border: 1px solid @btn2mianboderHColor;
      i {
        color: @btn2mianfontHColor;
      }
    }
  }
}
/deep/ .bgchangenode {
  background: #ccc;
}
/deep/.ant-modal-header {
  // padding-left: 24px;
}
/deep/.ant-modal-footer {
  padding: 0 32px 24px 0;
}
.btn-padding {
  display: none;
  // background: rgba(216, 219, 236, 0.27);
  border-radius: 4px;
  color: @btn3mianFontDColor;
  background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  // border: 1px solid @btn2mianboderDColor;
  // box-shadow: 0px 2px 4px 0px @btn2mianOutDColor, inset 0px -2px 0px 0px @btn2mianInsertDColor;
  // background-color: @btn2mianbgDColor;
  // color: @btn2mianfontDColor;
  // &:active {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor !important;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:focus {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:hover {
  //   color: @btn2mianfontHColor !important;
  //   border: 1px solid @btn2mianboderHColor !important;
  //   background-color: @btn2mianbgHColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutHColor, inset 0px -2px 0px 0px @btn2mianInsertHColor;
  // }
}
/deep/ .ant-table-header {
  // margin-top: -23px;
}
/deep/ .borderbox.ant-dropdown-open {
  border-color: @baseColor35 !important;
  color: @baseColor35 !important;
  i {
    color: @baseColor35 !important;
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}
/deep/ .ant-table-body {
  overflow: hidden !important;
  overflow-y: scroll !important;
}
.meiye-icon {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}
/deep/ .ant-input2 .meiye-sousuo {
  color: #707474;
  &:hover {
    color: @baseColor11;
  }
}

.linebox {
  display: flex;
  padding: 12px 0;

  .lfbox {
    width: 110px;
    font-size: 14px;
    color: @fontColor5;
    text-align: left;
    .imgxing {
      width: 8px;
      height: 22px;
      color: red;
    }
  }

  .rgbox {
    font-size: 14px;
    color: @btnColorfont1;
    // width: calc(100% - 120px);
    position: relative;

    .tuijia {
      font-size: 14px;
      color: @fontColor6;
    }
  }
}
/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 45px !important;
  height: 45px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}
.icons {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  border: 1px dashed #cbcbcb;
  .icon {
    font-size: 18px;
  }
  cursor: pointer;
  &:hover {
    color: @primaryColor;
    border: 1px dashed @primaryColor;
  }
}
.pdb-24 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2d3835;
}
.ant-dropdown-link {
  margin-left: 5px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2d3835;
  .meiye-shezhi,
  .meiye-moren1 {
    font-size: 15px;
    color: #9ea6a4;
  }
}
.meiye-jiantou_tree1 {
  color: @fontColor4;
}
.meiye-jiantou_tree2 {
  color: @fontColor5;
  transform: rotate(270deg);
  display: inline-block;
}
.pointer_tree {
  display: block;
  margin-top: 2px;
  width: 6px;
  height: 6px;
  background: @fontColor5;
  border-radius: 50%;
}
.table-manage {
  height: calc(100vh - 272px);
}
.fenye {
  text-align: center;
}
.titNum {
  position: absolute;
  left: 30px;
  top: 0;
  display: inline;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
/deep/.table-manage .ant-table-tbody > tr:last-child td {
  border-bottom: 1px solid @listbodylineColor !important;
}
/deep/.ant-tree li .ant-tree-node-content-wrapper {
  height: 32px;
  line-height: 32px;
}
</style>